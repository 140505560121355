@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

.navbar {
    background: #1c2237;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
  }
  .logo_box {
    margin-right: 10px;
  }
  
  .container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .navbar-icon {
    margin-right: 0.5rem;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: end;
  }
  
  .nav-item {
    height: 80px;
    border-bottom: 2px solid transparent;
  }
  
  .nav-item:hover {
    border-bottom: 2px solid #f00946;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-weight: 500;
    font-family: 'Quicksand';
  }
.nav-btn {
   font-weight: 500;
    font-family: 'Quicksand'; 
}
  .fa-bars {
    color: #fff;
  }
  
/* @font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: normal;
  src: url("https://fonts.googleapis.com/css2?family=Dosis:wght@800&display=swap") format("truetype");
} */

  .menu-icon {
    display: none;
  }

  .textTitle {
    font-weight: 600;
    font-family: 'Quicksand';
  } 

  .contact-btn {
       font-weight: 500;
    font-family: 'Quicksand'; 
  }


  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1c2237;
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
      font-family: 'Quicksand';
    }
  
    .nav-links:hover {
      color: #f00946;
      transform: scale(1.2);
      transition: all 0.3s ease;
    }
  
    .nav-item:hover {
      border: none;
    }
  
    .nav-item {
      width: 100%;
    }
  
    .navbar-logo {
      position: absolute;
      top: 20%;
      left: 5%;
      transform: translate(0%, 0%);

    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 120px;
       font-weight: 500;
    font-family: 'Quicksand'; 
    }


    
    .textTitle {
      font-family: 'Quicksand', Tahoma, Geneva, Verdana, sans-serif;
      /* font-family: 'Fredoka One' */
      visibility: hidden;
    }
    .logo-png {
      margin-top: -30;
      transform: translate(-100%, 60%);
      transform: scale(.9);
    }
  }
  